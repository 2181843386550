<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="cards">
        <div
          class="card"
          v-if="$getPermission($route.path + '/add')"
          @click="qrCodeVisible = true"
        >
          <img src="./assets/匿名.png" alt="" />
          <span>匿名发布</span>
        </div>
        <div
          class="card"
          v-if="$getPermission($route.path + '/add')"
          @click="$router.push($route.path + '/add')"
        >
          <img src="./assets/实名.png" alt="" />
          <span>实名发布</span>
        </div>
        <div
          class="card"
          v-if="$getPermission($route.path + '/history')"
          @click="$router.push($route.path + '/history')"
        >
          <img src="./assets/历史记录.png" alt="" />
          <span>历史清单</span>
        </div>
        <div
          class="card"
          v-if="$getPermission($route.path + '/table')"
          @click="$router.push($route.path + '/table')"
        >
          <img src="./assets/历史记录.png" alt="" />
          <span>数据报表</span>
        </div>
      </div>

      <div class="divider"></div>

      <div class="alert-title">注意事项：</div>

      <div class="alert">
        <img src="./assets/匿名.png" alt="" />
        <span>匿名发布：不记录发布人的任何信息。</span>
      </div>
      <div class="alert">
        <img src="./assets/实名.png" alt="" />
        <span
          >实名发布：正常记录发布人的信息。经核查属实，有益于提升公司安全生产管理工作的，公司将酌情给予奖励。</span
        >
      </div>
      <div class="alert">
        <img src="./assets/公开.png" alt="" />

        <span
          >是否公开：发布建议和举报时可选择是否公开发布，公开则所有人可见，非公开则只有自己和安全管理员可见。</span
        >
      </div>
      <div class="alert">
        <img src="./assets/历史记录.png" alt="" />

        <span>历史清单：历史清单可以查看发布人提交的清单数据。</span>
      </div>
      <div class="alert">
        <img src="./assets/附件.png" alt="" />

        <span
          >附件：<a
            href="https://qiniu.njszy.com/linkcloud/dev/2023/7/5/7361d9a96d76f84af1528bf5707ee91d_%E5%85%B3%E4%BA%8E%E4%BD%BF%E7%94%A8%E7%BF%8E%E4%BA%91%E5%B9%B3%E5%8F%B0%E2%80%9C%E5%AE%89%E5%85%A8%E5%BB%BA%E8%AE%AE%E4%B8%8E%E9%9A%90%E6%82%A3%E4%B8%BE%E6%8A%A5%E2%80%9D%E5%8A%9F%E8%83%BD%E7%9A%84%E9%80%9A%E7%9F%A5.pdf"
            target="_blank"
          >
            关于使用翎云平台“安全建议与隐患举报”功能的通知.pdf
          </a></span
        >
      </div>
    </a-card>

    <Hide :visible="qrCodeVisible" @cancel="qrCodeVisible = false" />
  </div>
</template>
  
  
<script>
import watermark from "@/mixins/watermark";
import Hide from "./components/hide.vue";
export default {
  mixins: [watermark],

  components: {
    Hide,
  },

  data() {
    return {
      qrCodeVisible: false,
    };
  },
};
</script>


<style lang="less" scoped>
.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 16px;
  gap: 24px;

  .card {
    border: 1px solid #eee;
    padding: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 32px;
      margin-right: 16px;
    }
    span {
      font-size: 20px;
    }
  }
}

.divider {
  margin: 0 16px;
  border-top: 1px solid #eee;
}

.alert-title {
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
}
.alert {
  padding: 0 16px 16px;
  img {
    width: 18px;
    margin-right: 8px;
  }
}
</style>