<template>
  <a-modal
    title="手机扫一扫，立刻匿名发布"
    :visible="visible"
    okText="下载二维码"
    @cancel="cancel"
    @ok="download"
    :forceRender="true"
  >
    <canvas
      id="report-canvas"
      style="margin: 16px auto; display: block"
      width="160px"
      height="160px"
    ></canvas>

    <div class="tips">
      <div class="title">注意事项</div>
      <div class="item">
        匿名发布是<strong>真匿名</strong>，此软件无法获取您的任何个人信息
      </div>
      <div class="item">
        由于无法获取您的个人信息，所以请务必使用同一个手机，同一个App扫码发布
      </div>
      <div class="item">此软件会在扫码后生成一个随机字符串用作用户指纹</div>
      <div class="item">指纹会在您重新扫码时查询历史</div>
    </div>
  </a-modal>
</template>
  
  
<script>
import { saveAs } from "file-saver";
export default {
  props: {
    visible: {
      type: Boolean,
    },
  },

  data() {
    return {
      url: "https://app.njszy.com/report.html",
      isCreatedQrCode: false,
    };
  },

  watch: {
    visible() {
      if (this.visible && !this.isCreatedQrCode) {
        const canvas = document.getElementById("report-canvas");
        const context = canvas.getContext("2d");

        new window.AwesomeQR.AwesomeQR({
          text: this.url,
          size: 160,
          margin: 0,
        })
          .draw()
          .then((dataURL) => {
            const image = new Image();
            image.onload = function () {
              context.drawImage(image, 0, 0);
            };
            image.src = dataURL;
          });

        this.isCreatedQrCode = true;
      }
    },
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    download() {
      const canvas = document.getElementById("report-canvas");
      if (!canvas) {
        console.log("no canvas");
        return;
      }
      canvas.toBlob(function (blob) {
        saveAs(blob, `匿名发布二维码.png`);
      });
    },
  },
};
</script>


<style lang="less" scoped>
.tips {
  color: #999;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .title {
    font-weight: bold;
    font-size: 14px;
  }
}
</style>